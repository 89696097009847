// simple logger for availability
// TODO: batch these logs to clean up the console
import { RBIEnv, env } from 'utils/environment';
import logger from 'utils/logger';

export const availabilityLogger = <
  I extends { _type?: string; name?: { locale: string } | string }
>(
  item: I | undefined | null,
  reason: string
) => {
  if (env() === RBIEnv.PROD || window.location.hostname === 'localhost') {
    return;
  }
  const name =
    !!item?.name && typeof item?.name === 'object'
      ? item.name?.locale
      : item?.name || 'Unknown name';
  logger.groupCollapsed(
    `Unavailable ${item?._type ? item._type : 'item of unknown type'}: ${name}`
  );
  logger.info({ item, message: reason });
  logger.groupEnd();
};
