import { useState } from 'react';

import { useConfigValue } from 'hooks/configs/use-config-value';
import useEffectOnce from 'hooks/use-effect-once';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import logger from 'utils/logger';

// TODO: RN Web - Implement proper code
const canMakePayments = (args: { networks: any }) =>
  Promise.resolve({ available: false, hasCards: false });

const isSupported = () => false;

export default function useCanUseApplePay() {
  const applePayPluginAvailable = useFlag(LaunchDarklyFlag.ENABLE_APPLE_PAY) && isSupported();
  const [canUseApplePay, setCanUseApplePay] = useState(false);
  const paymentsNetworks = useConfigValue({ key: 'apple', defaultValue: {}, isRegionalized: false })
    .paymentsNetworks;

  // TODO: RN - locale switching -- should maybe update this to a regular useEffect and only run once we have config value?
  useEffectOnce(() => {
    if (applePayPluginAvailable) {
      canMakePayments({
        networks: paymentsNetworks,
      })
        .then(({ available = false, hasCards = false }) => {
          const deviceCanMakePaymentsResult = available && hasCards;
          logger.info(`Device can${deviceCanMakePaymentsResult ? '' : 'not'} make payment`);
          setCanUseApplePay(deviceCanMakePaymentsResult);
        })
        .catch(() => {
          logger.info(`Device can not make payment - api rejected`);
          setCanUseApplePay(false);
        });
    }
  });

  return canUseApplePay;
}
