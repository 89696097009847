import logger from 'utils/logger';

import { ExceptionHandler } from './types';

export default function (exceptionHandler: ExceptionHandler) {
  window.onerror = (event, source, lineno, colno, error) => {
    if (error) {
      logger.error(JSON.stringify({ event, source, lineno, colno, error }));
      exceptionHandler(error, true);
    }
  };
}
