import { useCallback, useEffect, useRef, useState } from 'react';

import { useLocale } from 'state/intl';

export const useLayoutLanguageSelectorModal = () => {
  const { setHasShownLocaleSelector, shouldAutoShowSelector } = useLocale();

  const [show, setShow] = useState(false);
  const ref = useRef(false);

  const handleModalDismiss = useCallback(() => {
    setHasShownLocaleSelector();
    setShow(false);
  }, [setHasShownLocaleSelector]);

  /**
   * NOTE: this is a workaround for a known expo issue with modals loading outside the regular flow
   *  which results in the splash screen never hiding and users getting stuck
   * @see: https://github.com/expo/expo/issues/14099#issuecomment-1073352682
   *
   * TODO: RN - figure out a better workaround for this
   */
  useEffect(() => {
    ref.current = true;
    if (ref.current && shouldAutoShowSelector && !show) {
      setTimeout(() => {
        setShow(true);
      }, 250);
    }
    return function cleanup() {
      ref.current = false;
    };
  }, [shouldAutoShowSelector, show]);

  return {
    handleModalDismiss,
    showLanguageSelectorModal: show,
  };
};
